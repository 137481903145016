import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import "./pivottable-theme.css"; 
import { Card } from "@lightspeed/flame/Card";
import styled from "@emotion/styled";
import TableRenderers from "./TableRenderers";
import * as XLSX from 'xlsx'; // handling excel export
import { PivotData } from "./Utilities"; 


const StyledCard = styled(Card)`
  flex-grow: 1;
  position: relative;
  overflow: auto;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

// wrapped in forwardRef to pass ref from parent (Reports.js) to child (Table.js)
const Table = forwardRef(({ data, rows, cols, aggregatorName, vals, rendererName }, ref) => {

  console.log("AAA", rows);
  const [state, setState] = useState({
    rows: [], 
    cols: [], 
    aggregatorName: "Sum", // default aggregator
    vals: [], 
    rendererName: "Table", 
  });

    // store updated vals in state
    const handleTableChange = (s) => {
      setState(s);
      if (s.vals) {
        console.log("Selected vals (sum_field):", s.vals);
      }
    };

  // define all possible fields expected in data
  const allFields = [
    "transaction_date",
    "account_id",
    "account_description",
    "debit",
    "credit",
    "posting_comment",
    "xoption",
  ];

 // const allFields = [];
  // update state when data is ready
  useEffect(() => {
    if (data && data.length > 0) {
      // all possible columns/fields are present even if data didnt fetch
      const normalizedData = data.map((row) => {
        // fill them with null
        allFields.forEach((field) => {
          if (!(field in row)) {
            row[field] = null;
          }
        });
        return row;
      });

      setState((prevState) => ({
        ...prevState,
        rows, 
        cols, 
        aggregatorName, 
        vals, 
        rendererName, 
        data: normalizedData, // set data
        enableRowSort: true,
        enableColSort: false,
      }));
    } else {
      // when no data, create placeholder row with all fields set to null
      const placeholderRow = {};
      allFields.forEach((field) => (placeholderRow[field] = null));

      setState((prevState) => ({
        ...prevState,
        rows, 
        cols, 
        aggregatorName, 
        vals, 
        rendererName, 
        data: [placeholderRow], // use a placeholder row for data
        enableRowSort: true,
        enableColSort: false,
      }));
    }
  }, [data, rows, cols, aggregatorName, vals, rendererName]);

  // expose the export method to the parent (Reports.js)
  useImperativeHandle(ref, () => ({
    export: () => {
      const { rows, cols, vals, data } = state;
    
      // including aggregatorName to calculate totals dynamically
      const pivotData = new PivotData({
        data,
        rows,
        cols,
        vals,
        aggregatorName: state.aggregatorName, // used from import from ./Utilities
      });
    
      // fields included in the export: rows, cols, and vals (exclude Totals here)
      const fieldsToExport = [...rows, ...cols, ...vals]; // Exclude "Totals"
    
      // format data without Totals
      const exportData = pivotData.getRowKeys().map(rowKey => {
        const rowData = {};
    
        // row fields
        rows.forEach((row, index) => {
          rowData[row] = rowKey[index] || null;
        });
    
        // column fields
        cols.forEach(col => {
          rowData[col] = null; // Leave column fields blank for row data
        });
    
        // value fields
        vals.forEach(val => {
          const aggregator = pivotData.getAggregator(rowKey, []);
          rowData[val] = aggregator.value() || 0;
        });
    
        return rowData;
      });
    
      // Calculate and append the last Totals row
      const totalsRow = {};
      fieldsToExport.forEach(field => {
        if (vals.includes(field)) {
          // Sum vals
          totalsRow[field] = exportData.reduce((sum, row) => sum + (row[field] || 0), 0);
        } else {
          // Label for the last row
          totalsRow[field] = field === rows[0] ? "Totals" : null;
        }
      });
      exportData.push(totalsRow);
    
      // Convert data to Excel
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    
      // Download as report.xlsx
      XLSX.writeFile(workbook, "report.xlsx");
    },
    
    exportToCSV: () => {
      const { rows, cols, vals, data } = state;
    
      // including aggregatorName to calculate totals dynamically
      const pivotData = new PivotData({
        data,
        rows,
        cols,
        vals,
        aggregatorName: state.aggregatorName, // used from import from ./Utilities
      });
    
      // fields included in the export
      const fieldsToExport = [...rows, ...cols, "Totals"]; // Include only necessary fields: rows, cols, and "Totals"
    
      // format data with calculated totals
      const exportData = pivotData.getRowKeys().map(rowKey => {
        const rowData = {};
    
        // row fields
        rows.forEach((row, index) => {
          rowData[row] = rowKey[index] || null;
        });
    
        // column fields
        cols.forEach(col => {
          rowData[col] = null; // Leave column fields blank for row data
        });
    
        // Totals column: debit - credit
        const debit = pivotData.getAggregator(rowKey, []).value() || 0;
        const credit = pivotData.getAggregator([], rowKey).value() || 0;
        rowData["Totals"] = debit - credit;
    
        return rowData;
      });
    
      // Calculate and append the last Totals row
      const totalsRow = {};
      const debitTotal = exportData.reduce((sum, row) => sum + (row["debit"] || 0), 0);
      const creditTotal = exportData.reduce((sum, row) => sum + (row["credit"] || 0), 0);
    
      fieldsToExport.forEach(field => {
        if (field === "Totals") {
          // Last Totals = sum of debit - sum of credit
          totalsRow[field] = parseFloat((debitTotal - creditTotal).toFixed(2)); // Precision-safe subtraction
        } else {
          // Label for the last row
          totalsRow[field] = field === rows[0] ? "Totals" : null;
        }
      });
    
      exportData.push(totalsRow);
    
      // Convert data to CSV
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const csv = XLSX.utils.sheet_to_csv(worksheet);
    
      // Create BLOB for download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
   /* getColumns: () => {
      const { rows, cols } = state;
      return [...rows, ...cols]; // current rows and columns for updating report settings
    }*/
      getColumns: () => {
        const { rows, cols, vals } = state;
        return {
          rows: [...rows], 
          cols: [...cols], 
          vals: [...vals]  // include vals in columns
        };
      }
  }));

  return (
    <StyledCard>
      <Container>
        <PivotTableUI
          unusedOrientationCutoff={Infinity}
          data={data.length > 0 ? data : [{}]} // empty object if no data to keep table structure
          //onChange={(s) => setState(s)} 
          /*onChange={(s) => {
            setState(s);
            if (s.vals) {
              console.log("Selected vals (sum_field):", s.vals);  // log vals
            }
          }} */
            onChange={handleTableChange}
          renderers={TableRenderers} 
          {...state} // current state for PivotTable configuration
        />
      </Container>
    </StyledCard>
  );
});

export default Table;
