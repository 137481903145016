import Table from "../ui/reports/Table";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DatePicker from "../ui/reports/DatePicker";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const Reports = () => {
  const [data, setData] = useState([]); // state for fetched data
  const [reportColumns, setReportColumns] = useState(null); // state for report columns
  const [reportSum, setReportSum] = useState(null); // state for report sum (vals)
  const [reportCols, setReportCols] = useState([]); // state for report columns (cols)
  //const [reportCols, setReportCols] = useState(null); // state for report columns (cols)
  const tableRef = useRef();
  const [selectedReportId, setSelectedReportId] = useState(null);  
  const [selectedReportName, setSelectedReportName] = useState(""); 

  const [fromDate, setFromDate] = useState("");  // state for fromDate
  const [toDate, setToDate] = useState("");      // state for toDate

  //was:
  //const rows = ["transaction_date", "account_description", "debit", "credit"];
  
  // default values
  const defaultRows = ["transaction_date", "account_description", "debit", "credit"]; // default rows when no Report selected
  const defaultVals = ["debit_credit_amount"];  // Default value for vals
  //const cols = [];  // default cols
  const defaultCols = [];
  const aggregatorName = "Sum";  // default aggregator
  const vals_sort = ["desc"];  // sort direction
  const rendererName = "Table";  // renderer

  // Get user_id, company_id from Redux
  const userState = useSelector((state) => state.userState);
  const userId = userState.user.user_id;
  const companyId = useSelector((state) => state.database.currentCompany.id);
  const shopId = useSelector((state) => state.database.currentShop.shop_id);
  const companyType = useSelector((state) => state.database.currentCompany.type); // company type (RETAIL, RESTO, etc)
  const areaId = useSelector((state) => state.database.currentShop.area_id);

  const currentTab = useSelector((state) => state.ui.table); // Track the current tab

  // Sync logic
  const isReportRetailTab = currentTab === 6; // Report Retail corresponds to tab 6
  const isCompanyXorR = companyType === "X" || companyType === "R"; // Company types X or R


  const handleSync = () => {
    console.log("Sync triggered for Report Retail");
    console.log("Fetching data with:", { fromDate, toDate, selectedReportName, selectedReportId });
    // Call the fetchData function to ensure data is fetched
    fetchData(fromDate || "2023-01-01", toDate || "2023-12-31", selectedReportName || "default_report", selectedReportId || 0);
};

  // Log userId, companyId, and shopId
  useEffect(() => {
    console.log("User ID:", userId, "Company ID:", companyId, "Shop ID:", shopId);
  }, [userId, companyId, shopId]);

  useEffect(() => {
    console.log("Rendering Reports Component for RESTO");
  }, []);

  useEffect(() => {
    console.log(`Current Tab: ${currentTab}, Company Type: ${companyType}`);
    if (isReportRetailTab && isCompanyXorR) {
      console.log("Triggering SYNC...");
      handleSync(); // Trigger SYNC unconditionally
    }
  }, [currentTab, companyType]);


  // API endpoint based on the company type (Retail or Restaurant)
  const getApiUrl = () => {
    if (companyType === "RESTOK") {
      return "https://mapapimssql.datastems.com/api/admin/restok/report";
    } else if (companyType === "RESTO" || companyType === "RESTOL") {
      return "https://mapapimssql.datastems.com/api/admin/restol/report";
    } else {
      return "https://mapapimssql.datastems.com/api/admin/retailr/report";
    }
  };

  // fetch data from API
  const fetchData = async (fromDate, toDate, reportName, reportId) => {
    console.log("Fetching data with reportName:", reportName, "and reportId:", reportId); 
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const columnsToSend = reportColumns && reportColumns.length > 0 ? reportColumns : defaultRows;
    // cols_fields no have empty strings or null values
    const filteredCols = reportCols.filter(col => col !== "" && col !== null);

  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        token_user_id: userId,
        company_id: companyId,
        shop_id: companyType === 'RESTOK' || companyType === 'RESTO' ? areaId : shopId,
        register_id: 1,
        from_date: fromDate,
        to_date: toDate,
        xoption: "",
        report_id: reportId || "" || selectedReportId  , 
        report_name: reportName || selectedReportName || "" ,  
        selected_columns: columnsToSend,
        cols_fields: filteredCols.length > 0 ? filteredCols : defaultCols,
      }),
    };
  
    try {
      const response = await fetch(getApiUrl(), requestOptions);
      const data = await response.json();
      setData(data.results);
    } catch (error) {
      console.error(error);
    }
  };

  // Handle save report to update the persisted reportId and reportName
  const handleSaveReport = (reportId, reportName) => {
    console.log("Report saved - ID:", reportId, "Name:", reportName); // Log the report details
    setSelectedReportId(reportId);  // saved report ID
    setSelectedReportName(reportName);  // saved report name
  };

  return (
    <Wrapper>
      <Table
        ref={tableRef}
        data={data}
        // was: rows={rows}
        rows={reportColumns && reportColumns.length > 0 ? reportColumns : defaultRows} // defualt or columns accordig to selected Report
        //cols={reportCols && reportCols.length > 1 ? reportCols : cols}
        cols={reportCols && reportCols.length > 0 ? reportCols : defaultCols} 
        aggregatorName={aggregatorName}
        //vals={vals}
        //vals={reportSum && reportSum.length > 1 ? reportSum : vals}
        vals={reportSum && reportSum.length > 0 ? reportSum : defaultVals} 
        sortDirection={vals_sort}
        rendererName={rendererName}
      />
      <DatePicker 
        updateAction={(fromDate, toDate) => fetchData(fromDate, toDate, selectedReportName, selectedReportId)} // latest state for reportName and reportId
        tableRef={tableRef}
        userId={userId}  // pass userId to DatePicker which contains select list where it's used
        companyId={companyId}  // pass companyId to DatePicker
        companyType={companyType}
        fromDate={fromDate}  // from date state
        toDate={toDate}  // to date state
        onReportChange={(columns, reportId, reportName, sum_field, cols_fields) => {
          setReportColumns(columns);
          setReportSum(sum_field);  // save sum_field for vals 
            // filter out empty or null values from cols_fields 
          const filteredColsFields = cols_fields.filter(col => col !== "" && col !== null);

          setReportCols(filteredColsFields);
          setSelectedReportId(reportId);  // selected report_id (id)
          setSelectedReportName(reportName);  // selected report_name
        }}
        onSaveReport={(reportId, reportName) => handleSaveReport(reportId, reportName)}  // persist report after save
      />
    </Wrapper>
  );
};

export default Reports;
